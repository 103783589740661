<template>
  <validation-observer
    ref="observer"
    rules="required"
    v-slot="{ handleSubmit }"
  >
    <RightSideBar
      v-if="isOpen"
      @close="onClose"
      submit="Submit"
      button-class="bg-dynamicBackBtn text-white"
      @submit="handleSubmit(onSubmit('pending'))"
      :disabled-button="isLoading || !leaveBackDays.length"
      :loading="isLoading"
      width="width:520px"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg">Get Leave Back</span>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col justify-start items-start gap-4 pb-4">
          <p class="text-darkPurple text-base font-normal mb-0">
            Create a leave back request.
          </p>

          <div class="w-full flex flex-col justify-start items-start gap-4">
            <div
              class="w-full flex flex-col justify-start items-start gap-2 pb-1"
            >
              <span class="text-sm text-jet font-normal">Approved Leave</span>
              <div
                class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4"
              >
                <p class="text-romanSilver text-base font-normal">
                  <span v-if="leaveInfo.category">{{
                    leaveInfo.category.name
                  }}</span>
                  <span v-else>{{ leaveInfo.leaveRequest.category.name }}</span
                  >, {{ leaveInfo.daysRequested }} Day(s), ({{
                    $DATEFORMAT(
                      new Date(leaveInfo.requestStartDate),
                      "MMM dd, yyyy"
                    )
                  }}
                  -
                  {{
                    $DATEFORMAT(
                      new Date(leaveInfo.requestEndDate),
                      "MMM dd, yyyy"
                    )
                  }})
                </p>
              </div>
            </div>
            <div class="w-full text-darkPurple flex flex-col gap-2">
              <span class="font-normal text-sm">Leave Back Days</span>
              <div class="border border-romanSilver rounded-md">
                <datepicker
                  placeholder="--Select--"
                  input-class="date-input"
                  style="width: 100%"
                  :required="true"
                  :rules="['required']"
                  :disabled-dates="disabledDates"
                  v-model="requestDate"
                />
              </div>
            </div>
          </div>

          <div class="w-full flex flex-col items-end gap-7">
            <div
              class="hover:bg-cultured p-2 rounded-md"
              @click="handleAddLeaveBackDays(requestDate, '')"
            >
              <div
                class="flex flex-row justify-start items-center gap-2 cursor-pointer"
              >
                <c-icon icon-name="icon-plus" class="text-flame" size="xs" />
                <span class="text-base text-flame font-normal">Add New</span>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start gap-5">
              <div
                v-for="(item, i) in leaveBackDays"
                :key="JSON.stringify(item)"
              >
                <div
                  class="flex flex-row justify-between items-center gap-2 relative"
                >
                  <div
                    class="w-full flex flex-col justify-start items-start gap-2 pb-1"
                  >
                    <span class="text-sm text-jet font-normal">Days</span>
                    <div
                      class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4"
                    >
                      <span class="text-romanSilver text-base font-normal">
                        {{
                          $DATEFORMAT(
                            new Date(item.requestDate),
                            "dd MMM, yyyy"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <c-select
                    v-model="item.justification"
                    :options="justificationOptions"
                    label="Justification"
                    class="select-class w-full"
                    :rules="['required']"
                  />
                  <div
                    class="mt-5 cursor-pointer"
                    @click="handleRemoveLeaveBackDays(item, i)"
                  >
                    <c-icon icon-name="close" class="text-flame" size="xs" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="w-full flex flex-col justify-start items-start gap-2 pb-1"
          >
            <span class="text-sm text-jet font-normal"
              >Total Leave Back Days</span
            >
            <div
              class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4"
            >
              <span class="text-romanSilver text-base font-normal"
                >{{ leaveBackDays.length }} Day(s)</span
              >
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <c-button
          width="126px"
          class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
          :disabled="isLoading || !leaveBackDays.length"
          @click="handleSubmit(onSubmit('draft'))"
          >Save as Draft</c-button
        >
      </template>
    </RightSideBar>
  </validation-observer>
</template>

<script>
import { format } from "date-fns";
import Datepicker from "vuejs-datepicker";
import { ValidationObserver } from "vee-validate";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import RightSideBar from "@/components/RightSideBar";
import CButton from "@/components/Button";
import CIcon from "@/components/Icon";

export default {
  components: {
    Datepicker,
    ValidationObserver,
    RightSideBar,
    CIcon,
    CSelect,
    CButton,
  },
  computed: {
    disabledDates() {
      return {
        dates: this.disabledDatesArray,
        customPredictor: (date) =>
          !(
            date >= new Date(this.leaveInfo.requestStartDate) &&
            date <= new Date(this.leaveInfo.requestEndDate)
          ),
      };
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      leaveBackDays: [],
      disabledLeaveBackDays: [],
      leaveInfo: {},
      requestDate: new Date(),
      leaveBackId: null,
      actionType: null,
      disabledDatesArray: [],
      daysbackOptions: [],

      payload: {
        requestReason: null,
        requestStartDate: null,
        requestEndDate: null,
        leaveBackRequestDetails: [],
      },

      justificationOptions: ["Public Holidays", "Called back to work"],
    };
  },
  methods: {
    onOpen(leaveInfo) {
      this.leaveInfo = leaveInfo;
      this.leaveBackId = leaveInfo.leaveBackId;

      if (leaveInfo.leaveBackRequestDetails) {
        this.leaveBackDays = leaveInfo.leaveBackRequestDetails;
      }
      this.isOpen = true;
      this.isLoading = false;
      this.getLeaveBackRequests();
    },
    onClose() {
      this.leaveBackDays = [];
      this.requestDate = null;
      this.isOpen = false;
      this.isLoading = false;
    },
    handleRemoveLeaveBackDays(leaveBack, index) {
      this.leaveBackDays.splice(index, 1);
      this.disabledDatesArray = this.disabledDatesArray.filter(
        (item) =>
          new Date(leaveBack.requestDate).getDate() !== new Date(item).getDate()
      );
    },
    handleAddLeaveBackDays(requestDate, justificationData) {
      const daysBackArray = this.daysbackOptions.map((item) =>
        new Date(item).getTime()
      );
      const currentRequestDate = new Date(requestDate).getTime();
      if (daysBackArray.includes(currentRequestDate)) {
        const message = "Daysback already exists for this selected date!";
        this.$toasted.info(message, { duration: 5000 });
        return;
      }

      const foundItem = this.leaveBackDays.find(
        (i) => i.requestDate === requestDate
      );
      if (!foundItem) {
        this.disabledDatesArray.push(new Date(requestDate));
        this.leaveBackDays.push({ requestDate, justification: justificationData });
      } else
        this.$toasted.info("Leave back day already added!", { duration: 3000 });
    },
    onSubmit(submitStatus) {
      this.isLoading = true;

      const payload = {
        requestReason: "Request Leave back",
        leaveRequestId: this.leaveBackId
          ? this.leaveInfo.leaveRequest.id
          : this.leaveInfo.id,
        requestStartDate: format(
          new Date(this.leaveInfo.requestStartDate),
          "yyyy-MM-dd"
        ),
        requestEndDate: format(
          new Date(this.leaveInfo.requestEndDate),
          "yyyy-MM-dd"
        ),
        leaveBackRequestId: this.leaveBackId ? this.leaveBackId : null,
        leaveBackRequestDetails: this.leaveBackDays,
        daysRequested: this.leaveBackDays.length,
        status: submitStatus,
      };

      if (this.leaveBackId) {
        this.$_updateLeaveBack({ requestId: this.leaveBackId, payload })
          .then(() => {
            const message = "Leave back requested successfully!";
            this.$toasted.success(message, { duration: 3000 });
            this.$emit("success");
            this.onClose();
          })
          .catch((error) => {
            this.$toasted.error(error.message, { duration: 3000 });
            this.isLoading = false;
          });
      } else {
        this.$_applyForLeaveBack(payload)
          .then(() => {
            const message = "Leave back requested successfully!";
            this.$toasted.success(message, { duration: 3000 });
            this.$emit("success");
            this.onClose();
          })
          .catch(() => {
            const message = "Something went wrong. Please try again";
            this.$toasted.error(message, { duration: 3000 });
            this.isLoading = false;
          });
      }
    },
    async getLeaveBackRequests(params) {
      const payload = { id: this.$AuthUser.id, params };
      await this.$_getEmployeeLeaveBackRequest(payload).then(({ data }) => {
        const leaveRequest = data.requests?.filter(
          (item) => item.leaveRequest.id === this.leaveInfo.id
        );
        const combinedLeaveBackRequestDetails = [].concat(...leaveRequest.map(item => item.leaveBackRequestDetails));
        const leaveBackDays = combinedLeaveBackRequestDetails.map(
          (days) => new Date(days.requestDate)
        );
        this.requestDate = this.leaveInfo.requestStartDate;
        this.disabledDatesArray.push(...leaveBackDays);
      });
    },
  },
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
</style>
